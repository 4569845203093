import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import zaikoLogo from "../../components/zaiko.png"; // Import Zaiko logo

function Login({ setToken }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  // Handle login when the Enter key is pressed
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    const token = btoa(`${username}:${password}`);
    try {
      const response = await fetch(`${apiUrl}/auth/login`, {
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setToken(data.access_token);
      navigate("/home");
    } catch (error) {
      console.error("Error logging in:", error);
      alert("Login unsuccessful. Please check your username and password.");
    }
  };

  const handlePasswordReset = async () => {
    try {
      const response = await fetch(`${apiUrl}/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert(
        "If an account with that email exists, a password reset link has been sent."
      );
      setIsResettingPassword(false);
    } catch (error) {
      console.error("Error requesting password reset:", error);
      alert("Error requesting password reset. Please try again later.");
    }
  };

  const handleGoBack = () => {
    setIsResettingPassword(false);
  };

  return (
    <div className="login-container">
      <div className="login-box">
        {isResettingPassword ? (
          <>
            <img src={zaikoLogo} alt="Zaiko Logo" className="zaiko-logo" />
            <h2 className="login-title">Reset Password</h2>
            <p className="login-subtitle">
              Enter your email to reset your password
            </p>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handlePasswordReset} className="login-button">
              Send Reset Link
            </button>
            <span className="go-back" onClick={handleGoBack}>
              Back to Login
            </span>
          </>
        ) : (
          <>
            <img src={zaikoLogo} alt="Zaiko Logo" className="zaiko-logo" />
            <h2 className="login-title">Sign in to Zaiko</h2>
            <p className="login-subtitle">Efficient Inventory Management</p>
            <input
              type="text"
              placeholder="Username or Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyPress} // Listen for the Enter key on username input
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyPress} // Listen for the Enter key on password input
            />
            <button onClick={handleLogin} className="login-button">
              Login
            </button>
            <div className="login-links">
              <span
                className="forgot-password"
                onClick={() => setIsResettingPassword(true)}
              >
                Forgot password?
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Login;
