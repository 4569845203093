import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./FutureWaste.css";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const FutureWaste = () => {
  const [activeTab, setActiveTab] = useState("monthly");

  // Data for Monthly Historical Waste Trends
  const monthlyData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Waste Units",
        data: [107, 471, 225, 300, 375, 149],
        borderColor: "#003366", // Zaiko theme deep blue
        backgroundColor: "rgba(0, 51, 102, 0.2)", // Light blue
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Waste Cost ($)",
        data: [919.48, 3109.96, 1955.3, 2968.61, 2317.39, 1064.43],
        borderColor: "#4caf50", // Green for contrast
        backgroundColor: "rgba(76, 175, 80, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  // Data for Yearly Historical Waste Trends
  const yearlyData = {
    labels: ["2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Waste Units",
        data: [3190, 3465, 3688, 4137],
        borderColor: "#ff9800", // Orange for yearly contrast
        backgroundColor: "rgba(255, 152, 0, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Waste Cost ($)",
        data: [18874.75, 30031.52, 23970.22, 34247.72],
        borderColor: "#2196f3", // Blue for cost contrast
        backgroundColor: "rgba(33, 150, 243, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="future-waste">
      {/* Page Header */}
      <div className="page-header">
        <h2>Historical Waste Trends</h2>
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === "monthly" ? "active-tab" : ""}`}
            onClick={() => setActiveTab("monthly")}
          >
            Monthly
          </button>
          <button
            className={`tab-button ${activeTab === "yearly" ? "active-tab" : ""}`}
            onClick={() => setActiveTab("yearly")}
          >
            Yearly
          </button>
        </div>
      </div>

      {/* Graph Display */}
      <div className="graph-card">
        {activeTab === "monthly" && <Line data={monthlyData} />}
        {activeTab === "yearly" && <Line data={yearlyData} />}
      </div>
    </div>
  );
};

export default FutureWaste;
