import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import "./Waste.css";

// Register Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Waste = () => {
  const [activeTab, setActiveTab] = useState("byCategory");

  // Data for Waste by Category
  const categoryWasteData = {
    labels: ["Fruits", "Vegetables", "Dairy", "Bakery", "Meat"],
    datasets: [
      {
        label: "Waste (units)",
        data: [500, 800, 600, 400, 300],
        backgroundColor: ["#f44336", "#e57373", "#ffcdd2", "#d32f2f", "#b71c1c"],
        borderWidth: 1,
      },
    ],
  };

  // Data for Waste by Product
  const productWasteData = {
    labels: ["Product A", "Product B", "Product C", "Product D", "Product E"],
    datasets: [
      {
        label: "Waste (units)",
        data: [100, 200, 150, 300, 250],
        backgroundColor: ["#ff5722", "#ff8a65", "#ffccbc", "#e64a19", "#bf360c"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="waste-data-container">
      {/* Name Cards Row */}
      <div className="details-cards-row">
        <div className="waste-detail-card">
          <p className="description">Expiring in a Week</p>
          <p className="number">584</p>
        </div>
        <div className="waste-detail-card">
          <p className="description">Expiring in a Month</p>
          <p className="number">3,765</p>
        </div>
        <div className="waste-detail-card">
          <p className="description">Expiring in a Year</p>
          <p className="number">20,757</p>
        </div>
        <div className="waste-detail-card">
          <p className="description">Waste Cost per Unit</p>
          <p className="number">$7.98</p>
        </div>
      </div>

      {/* Graph Section */}
      <div className="graph-card">
        <h3>Waste Distribution</h3>
        <div className="graph-tabs">
          <button
            className={`tab-button ${activeTab === "byCategory" ? "active-tab" : ""}`}
            onClick={() => setActiveTab("byCategory")}
          >
            By Category
          </button>
          <button
            className={`tab-button ${activeTab === "byProduct" ? "active-tab" : ""}`}
            onClick={() => setActiveTab("byProduct")}
          >
            By Product
          </button>
        </div>
        <div className="graph-display">
          {activeTab === "byCategory" && <Bar data={categoryWasteData} />}
          {activeTab === "byProduct" && <Bar data={productWasteData} />}
        </div>
      </div>
    </div>
  );
};

export default Waste;
