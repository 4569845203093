import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./FutureStock.css";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const FutureStock = () => {
  const data = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5", "Week 6"],
    datasets: [
      {
        label: "Stock Levels",
        data: [1378, 1600, 1424, 1756, 593, 1173],
        borderColor: "#003366", // Deep blue for Zaiko theme
        backgroundColor: "rgba(0, 51, 102, 0.2)", // Light blue background
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Sales Levels",
        data: [1375, 1374, 1088, 1485, 498, 970],
        borderColor: "#4caf50", // Green for contrast
        backgroundColor: "rgba(76, 175, 80, 0.2)", // Light green background
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 12,
          },
          color: "#333", // Neutral text color for legend
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Weeks",
          font: {
            size: 14,
          },
          color: "#003366",
        },
        grid: {
          color: "#e0e0e0",
        },
      },
      y: {
        title: {
          display: true,
          text: "Levels",
          font: {
            size: 14,
          },
          color: "#003366",
        },
        beginAtZero: true,
        grid: {
          color: "#e0e0e0",
        },
      },
    },
  };

  return (
    <div className="future-stock">
      <h3>Demand Drop-Off Points</h3>
      <div className="graph-container">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default FutureStock;
