import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaTrash, FaSort, FaSortUp, FaSortDown } from "react-icons/fa"; // Import sorting icons
import "../Inventory/Inventory.css"; // Import shared CSS for consistent table styling

function InventoryDetail({ token }) {
  const { brand, productName } = useParams();
  const [inventoryData, setInventoryData] = useState([]);
  const [originalData, setOriginalData] = useState([]); // To store original data for resetting
  const [editedItems, setEditedItems] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // Sorting state
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";
  const navigate = useNavigate();

  // Fetch inventory details for the product
  const fetchInventoryDetail = useCallback(async () => {
    if (!token) return;
    try {
      const encodedBrand = encodeURIComponent(brand);
      const encodedProductName = encodeURIComponent(productName);
      const response = await fetch(
        `${apiUrl}/inventory/products?brand=${encodedBrand}&product_name=${encodedProductName}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const today = new Date().toISOString().split("T")[0];
      const filteredData = data.filter(
        (item) => !item.expiration_date || item.expiration_date >= today
      );

      setInventoryData(filteredData);
      setOriginalData(filteredData); // Store original data for reset

      // Redirect to inventory page if there are no items left
      if (filteredData.length === 0) {
        navigate("/inventory");
      }
    } catch (error) {
      console.error("Error fetching inventory details:", error);
      navigate("/inventory");
    }
  }, [token, apiUrl, brand, productName, navigate]);

  useEffect(() => {
    fetchInventoryDetail();
  }, [fetchInventoryDetail]);

  // Sorting function
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      setSortConfig({ key: null, direction: null });
      setInventoryData([...originalData]); // Reset to original order
      return;
    }

    const sortedData = [...inventoryData].sort((a, b) => {
      if (
        key === "quantity" ||
        key === "unit_price" ||
        key === "case_quantity" ||
        key === "selling_price" ||
        key === "margin"
      ) {
        return direction === "ascending" ? a[key] - b[key] : b[key] - a[key];
      }
      if (key === "expiration_date" || key === "date_added") {
        const dateA = new Date(a[key] || Infinity);
        const dateB = new Date(b[key] || Infinity);
        return direction === "ascending" ? dateA - dateB : dateB - dateA;
      }
      // String sorting for other keys (including distributor, unit_type, etc.)
      return direction === "ascending"
        ? a[key].localeCompare(b[key])
        : b[key].localeCompare(a[key]);
    });

    setSortConfig({ key, direction });
    setInventoryData(sortedData);
  };

  // Helper to display the correct sorting icon
  const getSortIcon = (key) => {
    if (!sortConfig.key || sortConfig.key !== key) return <FaSort />;
    if (sortConfig.direction === "ascending") return <FaSortUp />;
    if (sortConfig.direction === "descending") return <FaSortDown />;
    return <FaSort />;
  };

  const handleInputChange = (e, inventory_id, item) => {
    const { name, value } = e.target;

    setEditedItems((prevState) => {
      let updatedState = { ...prevState };
      const currentItem = updatedState[inventory_id] || {};
      updatedState[inventory_id] = { ...currentItem, [name]: value };

      // Parse relevant fields for calculations
      const caseQuantity = parseFloat(
        updatedState[inventory_id]?.case_quantity || item?.case_quantity || 0
      );
      const casePrice = parseFloat(
        updatedState[inventory_id]?.case_price || item?.case_price || 0
      );
      const unitPrice = parseFloat(
        updatedState[inventory_id]?.unit_price || item?.unit_price || 0
      );
      const quantity = parseFloat(
        updatedState[inventory_id]?.quantity || item?.quantity || 0
      );
      const unitType =
        updatedState[inventory_id]?.unit_type || item?.unit_type || "";

      if (unitType === "lb") {
        // Calculate total price for weight-based products dynamically
        updatedState[inventory_id].total_price = (unitPrice * quantity).toFixed(
          2
        );
      } else if (unitType === "case") {
        // Calculate total price for case-based products dynamically
        updatedState[inventory_id].total_price = (
          caseQuantity * casePrice
        ).toFixed(2);
      }

      const sellingPrice = parseFloat(
        updatedState[inventory_id]?.selling_price || item?.selling_price || 0
      );

      // Calculate margin dynamically
      updatedState[inventory_id].margin =
        unitPrice > 0
          ? (((sellingPrice - unitPrice) / unitPrice) * 100).toFixed(2)
          : 0;

      return updatedState;
    });
  };

  // Handle saving all changes
  const handleSaveAllClick = async () => {
    try {
      const updatePromises = Object.keys(editedItems).map(
        async (inventory_id) => {
          // Find the original data for this inventory item
          const originalData = inventoryData.find(
            (item) => item.inventory_id === Number(inventory_id)
          );

          // Merge the edited data with the original data
          const updatedData = {
            ...originalData,
            ...editedItems[inventory_id],
          };

          const response = await fetch(
            `${apiUrl}/inventory/update-inventory/${inventory_id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(updatedData),
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        }
      );

      await Promise.all(updatePromises);

      // Display an alert after successful save
      alert("All changes have been saved successfully!");

      // Redirect to inventory page after saving
      navigate("/inventory");
    } catch (error) {
      console.error("Error updating inventory:", error);
    }
  };

  // Handle delete action
  const handleDeleteClick = async (inventory_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmDelete) {
      try {
        const response = await fetch(`${apiUrl}/inventory/${inventory_id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        alert("Item deleted successfully");

        await fetchInventoryDetail();

        // If there are no items left, navigate back to the inventory page
        if (inventoryData.length === 0) {
          navigate("/inventory");
        }
      } catch (error) {
        console.error("Error deleting inventory item:", error);
        navigate("/inventory");
      }
    }
  };

  // Handle cancel action
  const handleCancelClick = () => {
    navigate("/inventory");
  };

  // Helper function to render inputs for the table
  const renderInput = (
    name,
    inventory_id,
    item,
    type = "number",
    step = "0.01" // Ensures fractional values
  ) => (
    <input
      type={type}
      name={name}
      value={editedItems[inventory_id]?.[name] ?? item[name]}
      onChange={(e) => handleInputChange(e, inventory_id, item)}
      step={step}
      min="0"
    />
  );

  const renderDropdown = (name, inventory_id, item) => (
    <select
      name={name}
      value={editedItems[inventory_id]?.[name] ?? item[name]}
      onChange={(e) => handleInputChange(e, inventory_id, item)}
    >
      <option value="lb">lb</option>
      <option value="case">case</option>
    </select>
  );

  return (
    <div className="inventory-container">
      <h1>
        Inventory Details for {brand} - {productName}
      </h1>
      <div className="inventory-actions">
        <button onClick={handleSaveAllClick}>Save All Changes</button>
        <button className="cancel-button" onClick={handleCancelClick}>
          Cancel
        </button>
      </div>
      <div className="inventory-table-wrapper">
        <div className="table-container">
          <div className="inventory-table">
            <table>
              <thead>
                <tr>
                  <th onClick={() => sortData("brand")}>
                    Brand {getSortIcon("brand")}
                  </th>
                  <th onClick={() => sortData("product_name")}>
                    Product Name {getSortIcon("product_name")}
                  </th>
                  <th onClick={() => sortData("date_added")}>
                    Date Added {getSortIcon("date_added")}
                  </th>
                  <th onClick={() => sortData("expiration_date")}>
                    Expiration Date {getSortIcon("expiration_date")}
                  </th>
                  <th onClick={() => sortData("distributor")}>
                    Distributor {getSortIcon("distributor")}
                  </th>
                  <th onClick={() => sortData("unit_type")}>
                    Unit Type {getSortIcon("unit_type")}
                  </th>
                  <th onClick={() => sortData("quantity")}>
                    Quantity {getSortIcon("quantity")}
                  </th>
                  <th onClick={() => sortData("case_quantity")}>
                    Case Quantity {getSortIcon("case_quantity")}
                  </th>
                  <th onClick={() => sortData("unit_price")}>
                    Unit Price {getSortIcon("unit_price")}
                  </th>
                  <th onClick={() => sortData("case_price")}>
                    Case Price {getSortIcon("case_price")}
                  </th>
                  <th onClick={() => sortData("selling_price")}>
                    Selling Price {getSortIcon("selling_price")}
                  </th>
                  <th>Total Price</th>
                  <th onClick={() => sortData("margin")}>
                    Margin {getSortIcon("margin")}
                  </th>
                  <th className="action-header">Actions</th>
                </tr>
              </thead>
              <tbody>
                {inventoryData.map((item) => (
                  <tr key={item.inventory_id}>
                    <td>{item.brand}</td>
                    <td>{item.product_name}</td>
                    <td>{item.date_added || ""}</td>
                    <td>
                      <input
                        type="date"
                        name="expiration_date"
                        value={
                          editedItems[item.inventory_id]?.expiration_date ??
                          item.expiration_date
                        }
                        onChange={(e) =>
                          handleInputChange(e, item.inventory_id, item)
                        }
                      />
                    </td>
                    <td>
                      {renderInput(
                        "distributor",
                        item.inventory_id,
                        item,
                        "text"
                      )}
                    </td>
                    <td>
                      {renderDropdown("unit_type", item.inventory_id, item)}
                    </td>
                    <td>
                      {renderInput(
                        "quantity",
                        item.inventory_id,
                        item,
                        "number",
                        "0.01"
                      )}
                    </td>
                    <td>
                      {renderInput("case_quantity", item.inventory_id, item)}
                    </td>
                    <td>
                      {renderInput(
                        "unit_price",
                        item.inventory_id,
                        item,
                        "number",
                        "0.01"
                      )}
                    </td>
                    <td>
                      {renderInput(
                        "case_price",
                        item.inventory_id,
                        item,
                        "number",
                        "0.01"
                      )}
                    </td>
                    <td>
                      {renderInput(
                        "selling_price",
                        item.inventory_id,
                        item,
                        "number",
                        "0.01"
                      )}
                    </td>
                    <td>
                      <input
                        type="number"
                        name="total_price"
                        step="0.01"
                        value={
                          editedItems[item.inventory_id]?.total_price ??
                          parseFloat(item.total_price).toFixed(2)
                        }
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="margin"
                        value={
                          editedItems[item.inventory_id]?.margin ??
                          parseFloat(item.margin).toFixed(2)
                        }
                        readOnly
                      />
                    </td>
                    <td className="action-cell">
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteClick(item.inventory_id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryDetail;
