import React, { useState } from "react";
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./StockData.css";

// Register Chart.js components
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const StockData = () => {
  // State for Category/Supplier Graphs
  const [activeCategoryGraph, setActiveCategoryGraph] = useState("categoryBar");

  // State for Sell-Through Rate Graphs
  const [activeSellThroughGraph, setActiveSellThroughGraph] = useState("weekly");

  // Data for Category Graphs
  const categoryData = {
    labels: ["Fruits", "Vegetables", "Dairy", "Bakery", "Meat"],
    datasets: [
      {
        label: "Stock by Category",
        data: [200, 150, 300, 100, 250],
        backgroundColor: ["#4caf50", "#81c784", "#c8e6c9", "#388e3c", "#2e7d32"],
        borderWidth: 1,
      },
    ],
  };

  // Data for Supplier Graphs
  const supplierData = {
    labels: ["Supplier A", "Supplier B", "Supplier C", "Supplier D", "Supplier E"],
    datasets: [
      {
        label: "Stock by Supplier",
        data: [100, 200, 150, 300, 250],
        backgroundColor: ["#2196f3", "#64b5f6", "#bbdefb", "#1976d2", "#0d47a1"],
        borderWidth: 1,
      },
    ],
  };

  // Data for Sell-Through Rate Graphs
  const weeklyData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
    datasets: [
      {
        label: "Sell-Through Rate (%)",
        data: [61.52, 68.76, 52.03, 84.35, 73.25],
        borderColor: "#4caf50",
        backgroundColor: "rgba(76, 175, 80, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const monthlyData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Sell-Through Rate (%)",
        data: [72.07, 63.06, 52.06, 66.05, 51.08],
        borderColor: "#2196f3",
        backgroundColor: "rgba(33, 150, 243, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="stock-data-container">
      {/* Left Side: Graphs */}
      <div className="graphs-section">
        <div className="graph-card">
          <h3>Stock Distribution</h3>
          <div className="graph-tabs">
            <button
              className={`tab-button ${activeCategoryGraph === "categoryBar" ? "active-tab" : ""}`}
              onClick={() => setActiveCategoryGraph("categoryBar")}
            >
              Category
            </button>


            {/* <button
              className={`tab-button ${activeCategoryGraph === "supplierPie" ? "active-tab" : ""}`}
              onClick={() => setActiveCategoryGraph("supplierPie")}
            >
              Supplier
            </button> */}

            <button
              className={`tab-button ${activeCategoryGraph === "supplierBar" ? "active-tab" : ""}`}
              onClick={() => setActiveCategoryGraph("supplierBar")}
            >
              Supplier
            </button>


          </div>
          <div className="graph-display">
            {activeCategoryGraph === "categoryBar" && <Bar data={categoryData} />}
            {activeCategoryGraph === "supplierPie" && <Pie data={supplierData} />}
            {activeCategoryGraph === "supplierBar" && <Bar data={supplierData} />}
          </div>
        </div>

        <div className="graph-card">
          <h3>Sell-Through Rate</h3>
          <div className="graph-tabs">
            <button
              className={`tab-button ${activeSellThroughGraph === "weekly" ? "active-tab" : ""}`}
              onClick={() => setActiveSellThroughGraph("weekly")}
            >
              Weekly
            </button>
            <button
              className={`tab-button ${activeSellThroughGraph === "monthly" ? "active-tab" : ""}`}
              onClick={() => setActiveSellThroughGraph("monthly")}
            >
              Monthly
            </button>
          </div>
          <div className="graph-display">
            {activeSellThroughGraph === "weekly" && <Line data={weeklyData} />}
            {activeSellThroughGraph === "monthly" && <Line data={monthlyData} />}
          </div>
        </div>
      </div>

      {/* Right Side: Detail Cards */}
      <div className="details-cards">
        <div className="detail-card">
          <p className="description">Total Stock (Units)</p>
          <p className="number">507</p>
          
        </div>
        <div className="detail-card">
          <p className="description">Stock Per Day</p>
          <p className="number">77</p>
          
        </div>

        <div className="detail-card">
          <p className="description">Out-Of-Stock Alerts</p>
          <p className="number">7</p>
          
        </div>



        <div className="detail-card">
          <p className="description">Low-Stock Alerts</p>
          <p className="number">2</p>
          
        </div>
        <div className="detail-card">
          <p className="description">Stock Turnover Rate</p>  
          <p className="number">20.05%</p>
          
        </div>
      </div>
    </div>
  );
};

export default StockData;
