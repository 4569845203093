// LoadingPage.js
import React from "react";
import ReactLoading from "react-loading";
import "./LoadingPage.css";

function LoadingPage() {
  return (
    <div className="loading-page">
      <div className="loader-container">
        <ReactLoading
          type={"bars"}
          color={"#003366"} // Green bars
          height={100}
          width={100}
        />
        <p>Uploading inventory... Please wait.</p>
      </div>
    </div>
  );
}

export default LoadingPage;
