import React, { useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./SalesRevenue.css";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip, Legend);

const SalesRevenue = () => {
  const [activeTab, setActiveTab] = useState("weekly");
  const [activeBreakdownTab, setActiveBreakdownTab] = useState("byProduct");

  // Weekly, Monthly, and Yearly Revenue Data
  const weeklyData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
    datasets: [
      {
        label: "Weekly Sales ($)",
        data: [21686.47, 48407.38, 34304.01, 39050.04, 38298.15],
        borderColor: "#4caf50",
        backgroundColor: "rgba(76, 175, 80, 0.2)",

        // backgroundColor: ["#4caf50", "#81c784", "#c8e6c9", "#388e3c", "#2e7d32"],
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const monthlyData = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Monthly Sales ($)",
        data: [117747.2, 98561.38, 101054.69, 123360.46, 80669.71],
        borderColor: "#2196f3",
        backgroundColor: "rgba(33, 150, 243, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const yearlyData = {
    labels: ["2020", "2021", "2022", "2023"],
    datasets: [
      {
        label: "Yearly Sales ($)",
        data: [671223.12, 595513.02, 438748.76, 501891.23],
        borderColor: "#ff9800",
        backgroundColor: "rgba(255, 152, 0, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  // Data for Top 20 Products (Bar Chart)
  const productData = {
    labels: ["Product 1", "Product 2", "Product 3", "Product 4", "Product 5"],
    datasets: [
      {
        label: "Sales ($)",
        data: [9825.55, 14939.16, 9066.66, 15228.13, 10183.53],
        // backgroundColor: "#4caf50",
        backgroundColor: ["#4caf50", "#81c784", "#c8e6c9", "#388e3c", "#2e7d32"],
      },
    ],
  };

  // Data for Category Sales (Bar Chart)
  const categoryData = {
    labels: ["Fruits", "Vegetables", "Dairy", "Bakery", "Meat"],
    datasets: [
      {
        label: "Sales ($)",
        data: [185645.35, 112812.95, 78883.04, 145890.38, 55897.69],
        // backgroundColor: "#2196f3",
        backgroundColor: ["#2196f3", "#64b5f6", "#bbdefb", "#1976d2", "#0d47a1"],
      },
    ],
  };

  // Data for Growth Margin & Profit (Bar Chart)
  const growthMarginData = {
    labels: ["Fruits", "Vegetables", "Dairy", "Bakery", "Meat"],
    datasets: [
      {
        label: "Growth Margin (%)",
        data: [18.05, 24.85, 12.77, 12.95, 19.91],
        backgroundColor: "#81c784",
        // backgroundColor: ["#4caf50", "#81c784", "#c8e6c9", "#388e3c", "#2e7d32"],
      },
      {
        label: "Profit Margin (%)",
        data: [47.25, 46.68, 36.05, 18.02, 39.6],
        backgroundColor: "#388e3c",
        // backgroundColor: ["#2196f3", "#64b5f6", "#bbdefb", "#1976d2", "#0d47a1"],
      },
    ],
  };

  return (
    <div className="sales-revenue">
      {/* Total Revenue Description */}
      <div className="revenue-row">
        {/* Revenue Graph */}
        <div className="revenue-graph">
          <div className="revenue-description">
            <div>
              <h2>Total Revenue</h2>
              <p className="total-amount">$51,200</p>
              <p className="growth">
                <span className="growth-percentage">+22.5%</span> today
              </p>
            </div>
            <div className="menu-buttons">
              <button
                className={`menu-button ${activeTab === "weekly" ? "active-menu" : ""}`}
                onClick={() => setActiveTab("weekly")}
              >
                Weekly
              </button>
              <button
                className={`menu-button ${activeTab === "monthly" ? "active-menu" : ""}`}
                onClick={() => setActiveTab("monthly")}
              >
                Monthly
              </button>
              <button
                className={`menu-button ${activeTab === "yearly" ? "active-menu" : ""}`}
                onClick={() => setActiveTab("yearly")}
              >
                Yearly
              </button>
            </div>
          </div>
          <div className="graph-display">
            {activeTab === "weekly" && <Line data={weeklyData} />}
            {activeTab === "monthly" && <Line data={monthlyData} />}
            {activeTab === "yearly" && <Line data={yearlyData} />}
          </div>
        </div>

        {/* Name Cards */}
        {/* <div className="side-cards">
          <div className="detail-card">
            <p className="description">Total Discounts</p>
            <p className="number">$49,012</p>
          </div>
          <div className="detail-card-time">
            <p className="description">Peak Sales Times</p>
            <p className="number">2024-11-25 to 2024-12-02</p>
          </div>
        </div> */}
      </div>


      {/* <div className="side-cards">
          <div className="detail-card">
            <p className="description">Total Discounts</p>
            <p className="number">$49,012</p>
          </div>
          <div className="detail-card">
            <p className="description">Peak Sales Times</p>
            <p className="number">2024-11-25 to 2024-12-02</p>
          </div>
        </div> */}



      {/* Sales Breakdown */}
      <div className="breakdown-card">
        <h3>Sales Breakdown</h3>
        <div className="tabs">
          <button
            className={`tab-button ${activeBreakdownTab === "byProduct" ? "active-tab" : ""}`}
            onClick={() => setActiveBreakdownTab("byProduct")}
          >
            By Product (Top 20)
          </button>
          <button
            className={`tab-button ${activeBreakdownTab === "byCategory" ? "active-tab" : ""}`}
            onClick={() => setActiveBreakdownTab("byCategory")}
          >
            By Category
          </button>
        </div>
        <div className="chart-container">
          {activeBreakdownTab === "byProduct" && <Bar data={productData} />}
          {activeBreakdownTab === "byCategory" && <Bar data={categoryData} />}
        </div>
      </div>

      {/* Growth Margin & Profit */}
      <div className="growth-card">
        <h3>Growth Margin & Profit</h3>
        <div className="chart-container">
          <Bar data={growthMarginData} />
        </div>
      </div>
    </div>
  );
};

export default SalesRevenue;
