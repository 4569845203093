import React, { useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./Pricing.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend
);

const Pricing = () => {
  const [activeTab, setActiveTab] = useState("product");

  // Data for Time-to-Sell Distribution by Product
  const productData = {
    labels: [
      "Product 1",
      "Product 2",
      "Product 3",
      "Product 4",
      "Product 5",
      "Product 6",
    ],
    datasets: [
      {
        label: "Time to Sell (Days)",
        data: [5, 40, 18, 41, 45, 34],
        backgroundColor: "#003366",
        borderColor: "#001f4d",
        borderWidth: 1,
      },
    ],
  };

  // Data for Time-to-Sell Distribution by Category
  const categoryData = {
    labels: ["Fruits", "Vegetables", "Dairy", "Bakery", "Meat", "Seafood"],
    datasets: [
      {
        label: "Time to Sell (Days)",
        data: [48, 59, 44, 48, 46, 59],
        backgroundColor: "#4caf50",
        borderColor: "#388e3c",
        borderWidth: 1,
      },
    ],
  };

  // Dynamic Pricing Data
  const dynamicPricingData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
    datasets: [
      {
        label: "Optimal Discounting (%)",
        data: [5, 10, 7, 8, 15],
        borderColor: "#ff9800",
        backgroundColor: "rgba(255, 152, 0, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  // Demand Drop-Off Points Data
  const demandData = {
    labels: ["Point 1", "Point 2", "Point 3", "Point 4"],
    datasets: [
      {
        label: "Demand Intensity",
        data: [50, 40, 25, 10],
        borderColor: "#673ab7",
        backgroundColor: "rgba(103, 58, 183, 0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="pricing-page">
      {/* Time-to-Sell Distribution Section */}
      <div className="section">
        <h2>Time-to-Sell Distribution</h2>
        <div className="tabs">
          <button
            className={`tab-button ${
              activeTab === "product" ? "active-tab" : ""
            }`}
            onClick={() => setActiveTab("product")}
          >
            By Product
          </button>
          <button
            className={`tab-button ${
              activeTab === "category" ? "active-tab" : ""
            }`}
            onClick={() => setActiveTab("category")}
          >
            By Category
          </button>
        </div>
        <div className="chart-container">
          {activeTab === "product" && <Bar data={productData} />}
          {activeTab === "category" && <Bar data={categoryData} />}
        </div>
      </div>

      {/* Demand Drop-Off Points Section */}
      <div className="section">
        <h2>Demand Drop-Off Points</h2>
        <p>Based on results from Total Sales</p>
        <div className="chart-container">
          <Line data={demandData} />
        </div>
      </div>

      {/* Dynamic Pricing Section */}
      <div className="section">
        <h2>Dynamic Pricing</h2>
        <div className="chart-container">
          <Line data={dynamicPricingData} />
        </div>
        <p>Discounting Impact on Waste Reduction</p>
      </div>
    </div>
  );
};

export default Pricing;
