import React, { useState, useEffect } from "react";
import "./Report.css";
import StockData from "./StockData";
import SalesRevenue from "./SalesRevenue";
import Waste from "./Waste";
import FutureStock from "./FutureStock";
import Pricing from "./Pricing";
import FutureWaste from "./FutureWaste";

const Report = ({token}) => {
  const [activeTab, setActiveTab] = useState("current"); // Main tab (Real-Time Data or Forecasting)
  const [activeCategory, setActiveCategory] = useState("stock"); // Category within Real-Time Data
  const [company, setCompany] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  useEffect(() => {
    const fetchUser = async () => {
      if (!token) return;
      try {
        const response = await fetch(`${apiUrl}/users/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCompany(data.company); // Fetch the company name dynamically
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [token, apiUrl]);

  const handleTabSwitch = (tab) => setActiveTab(tab);
  const handleCategorySwitch = (category) =>
    setActiveCategory(category.toLowerCase()); // Ensure lowercase

  return (
    <div className="report-dashboard">
      {/* Main Header */}

      <h1>{company ? `${company} Dashboard` : "Loading Dashboard..."}</h1>

      {/* Tabs */}
      <div className="tabs-container">
        <button
          className={`tab-button ${
            activeTab === "current" ? "active-tab" : ""
          }`}
          onClick={() => handleTabSwitch("current")}
        >
          Real-Time Data
        </button>
        <button
          className={`tab-button ${
            activeTab === "forecasting" ? "active-tab" : ""
          }`}
          onClick={() => handleTabSwitch("forecasting")}
        >
          Predictive Insights
        </button>
      </div>

      {/* Main Content */}
      <main className="main-content">
        {activeTab === "current" && (
          <section className="real-time-data">
            {/* Sidebar */}
            <aside className="category-sidebar">
              <button
                className={`category-button ${
                  activeCategory === "stock" ? "active-category" : ""
                }`}
                onClick={() => handleCategorySwitch("stock")}
              >
                Stock
              </button>
              <button
                className={`category-button ${
                  activeCategory === "sales" ? "active-category" : ""
                }`}
                onClick={() => handleCategorySwitch("sales")}
              >
                Sales/Revenue
              </button>
              <button
                className={`category-button ${
                  activeCategory === "waste" ? "active-category" : ""
                }`}
                onClick={() => handleCategorySwitch("waste")}
              >
                Expiring/Waste
              </button>
            </aside>

            {/* Content Area */}
            <div className="category-content">
              {activeCategory === "stock" && <StockData />}
              {activeCategory === "sales" && <SalesRevenue />}
              {activeCategory === "waste" && <Waste />}
            </div>
          </section>
        )}

        {activeTab === "forecasting" && (
          <section className="forecasting-data">
            {/* Sidebar */}
            <aside className="category-sidebar">
              <button
                className={`category-button ${
                  activeCategory === "stock" ? "active-category" : ""
                }`}
                onClick={() => handleCategorySwitch("stock")}
              >
                Stock
              </button>
              <button
                className={`category-button ${
                  activeCategory === "pricing" ? "active-category" : ""
                }`}
                onClick={() => handleCategorySwitch("pricing")}
              >
                Sales/Pricing
              </button>
              <button
                className={`category-button ${
                  activeCategory === "waste" ? "active-category" : ""
                }`}
                onClick={() => handleCategorySwitch("waste")}
              >
                Waste
              </button>
            </aside>

            {/* Content Area */}
            <div className="category-content">
              {activeCategory === "stock" && <FutureStock />}
              {activeCategory === "pricing" && <Pricing />}
              {activeCategory === "waste" && <FutureWaste />}
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export default Report;
