import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./InventoryEdit.css"; // Import a CSS file for styling

function InventoryEdit({ token }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { products, distributor_id } = location.state || {};

  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const [editedProducts, setEditedProducts] = useState([]);
  const [priceChanges, setPriceChanges] = useState({});

  const getDefaultExpirationDate = () => {
    const today = new Date();
    const expirationDate = new Date(today.setDate(today.getDate() + 90));
    return expirationDate.toISOString().split("T")[0];
  };

  const updateAllDistributors = (value) => {
    const updatedProducts = editedProducts.map((product) => ({
      ...product,
      distributor: value,
    }));
    setEditedProducts(updatedProducts);
  };

  useEffect(() => {
    if (products.length > 0) {
      const initializedProducts = products
        .filter((product) => product.product_name && product.unit_price) // Remove empty products
        .map((product) => {
          const unitPrice = parseFloat(product.unit_price || 0).toFixed(2);
          const casePrice = parseFloat(product.case_price || 0).toFixed(2);
          const caseQuantity = parseFloat(product.case_quantity || 0).toFixed(
            2
          );
          const quantity = parseFloat(product.quantity || 0).toFixed(2);
          const unitType = (product.unit_type || "case").toLowerCase();

          let totalPrice =
            caseQuantity > 0 && casePrice > 0
              ? (caseQuantity * casePrice).toFixed(2)
              : (unitPrice * quantity).toFixed(2);

          const suggestedPrice = (parseFloat(unitPrice) * 1.3).toFixed(2);
          const sellingPrice = parseFloat(
            product.selling_price || suggestedPrice
          ).toFixed(2);

          const margin =
            sellingPrice > 0
              ? ((sellingPrice - unitPrice) / unitPrice) * 100
              : 0;

          return {
            distributor: product.distributor || "",
            product_name: product.product_name || "",
            brand: product.brand || "",
            quantity,
            expiration_date:
              product.expiration_date || getDefaultExpirationDate(),
            case_quantity: caseQuantity,
            unit_type: unitType,
            unit_price: unitPrice,
            case_price: casePrice,
            selling_price: sellingPrice,
            total_price: totalPrice,
            margin: margin.toFixed(2),
            _suggested_price: suggestedPrice,
          };
        });
      setEditedProducts(initializedProducts);
    }
  }, [products]);

  useEffect(() => {
    editedProducts.forEach((product, index) => {
      if (product.distributor || product.unit_price !== 0) {
        fetchPriceComparison(product, index);
      }
    });
  }, [editedProducts]);

  const fetchPriceComparison = async (product, index) => {
    try {
      const response = await fetch(`${apiUrl}/inventory/compare-price`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          brand: product.brand,
          product_name: product.product_name,
          distributor: product.distributor,
          unit_price: product.unit_price,
          case_price: product.case_price,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPriceChanges((prevState) => ({
        ...prevState,
        [index]: data.price_change,
      }));
    } catch (error) {
      console.error("Error fetching price comparison:", error);
    }
  };

  const updateMargin = (index, updatedProducts) => {
    const { unit_price, selling_price } = updatedProducts[index];
    const margin =
      selling_price > 0 ? ((selling_price - unit_price) / unit_price) * 100 : 0;
    updatedProducts[index].margin = margin.toFixed(2);
    setEditedProducts(updatedProducts);
  };

  const updateTotalPrice = (index, updatedProducts) => {
    const { case_quantity, case_price, unit_price, quantity } =
      updatedProducts[index];

    let calculatedTotalPrice =
      case_quantity > 0 && case_price > 0
        ? case_quantity * case_price
        : unit_price * quantity;

    updatedProducts[index].total_price = calculatedTotalPrice.toFixed(2);
    setEditedProducts(updatedProducts);
  };

  const handleInputChange = (index, field, value) => {
    const updatedProducts = [...editedProducts];
    const product = updatedProducts[index];

    if (
      ["quantity", "case_quantity", "unit_price", "case_price"].includes(field)
    ) {
      // Allow only numbers with up to 2 decimal places
      if (/^\d*\.?\d{0,2}$/.test(value)) {
        product[field] = value;
      }
    } else {
      product[field] = value;
    }

    if (
      ["unit_price", "quantity", "case_price", "case_quantity"].includes(field)
    ) {
      updateTotalPrice(index, updatedProducts);
    }

    if (field === "unit_price") {
      product.selling_price = (product.unit_price * 1.3).toFixed(2);
    }

    updateMargin(index, updatedProducts);
    setEditedProducts(updatedProducts);
  };

  const handleInputBlur = (index, field) => {
    const updatedProducts = [...editedProducts];
    if (
      [
        "unit_price",
        "selling_price",
        "case_price",
        "quantity",
        "case_quantity",
      ].includes(field)
    ) {
      updatedProducts[index][field] =
        updatedProducts[index][field] === "" ||
        updatedProducts[index][field] === null
          ? "0.00"
          : parseFloat(updatedProducts[index][field]).toFixed(2);
    }
    setEditedProducts(updatedProducts);
  };

  const handleAddRow = () => {
    const newProduct = {
      distributor: "",
      product_name: "",
      brand: "",
      quantity: "0.00",
      expiration_date: getDefaultExpirationDate(),
      case_quantity: "0.00",
      unit_type: "case",
      unit_price: "0.00",
      case_price: "0.00",
      selling_price: "",
      total_price: "0.00",
      margin: "0.00",
    };
    setEditedProducts([...editedProducts, newProduct]);
  };

  const handleDeleteRow = (index) => {
    const updatedProducts = editedProducts.filter((_, i) => i !== index);
    setEditedProducts(updatedProducts);
  };

  const handleConfirmClick = async () => {
    try {
      const response = await fetch(`${apiUrl}/inventory/confirm-inventory`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          distributor_id, // Include distributor_id
          items: editedProducts,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert("Inventory successfully added!");
      navigate("/inventory");
    } catch (error) {
      console.error("Error confirming inventory:", error);
      alert("Failed to add inventory.");
    }
  };

  return (
    <div className="center-wrapper">
      <div className="inventory-edit-container">
        <h1>Edit and Confirm Inventory Items</h1>
        <div className="products-list">
          {editedProducts.map((item, index) => (
            <div key={index} className="product-item">
              {Object.keys(item).map((key) => {
                if (key === "_suggested_price") return null;

                if (key === "total_price") {
                  return (
                    <div key={key} className="form-group">
                      <label>{key.replace(/_/g, " ").toUpperCase()}:</label>
                      <input
                        type="text"
                        value={item[key]}
                        readOnly
                        className="input"
                      />
                    </div>
                  );
                }

                if (key === "unit_type") {
                  return (
                    <div key={key} className="form-group">
                      <label>{key.replace(/_/g, " ").toUpperCase()}:</label>
                      <select
                        className="input"
                        value={item[key]}
                        onChange={(e) =>
                          handleInputChange(index, key, e.target.value)
                        }
                      >
                        <option value="case">Case</option>
                        <option value="lb">lb</option>
                      </select>
                    </div>
                  );
                }

                return (
                  <div key={key} className="form-group">
                    <label>{key.replace(/_/g, " ").toUpperCase()}:</label>
                    <input
                      type="text"
                      value={item[key]}
                      onChange={(e) =>
                        handleInputChange(index, key, e.target.value)
                      }
                      onBlur={() => handleInputBlur(index, key)}
                      readOnly={key === "margin"}
                      className="input"
                    />
                  </div>
                );
              })}

              {priceChanges[index] !== undefined && (
                <div className="price-change">
                  {priceChanges[index] > 0 ? (
                    <span style={{ color: "red" }}>
                      Price increased by {priceChanges[index]}%
                    </span>
                  ) : priceChanges[index] < 0 ? (
                    <span style={{ color: "green" }}>
                      Price decreased by {Math.abs(priceChanges[index])}%
                    </span>
                  ) : (
                    <span>No price change</span>
                  )}
                </div>
              )}

              <button
                className="delete-btn"
                onClick={() => handleDeleteRow(index)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        <div className="action-buttons">
          <button onClick={handleAddRow}>Add New Item</button>
          <button onClick={handleConfirmClick}>
            Confirm and Add to Inventory
          </button>
        </div>
      </div>
    </div>
  );
}

export default InventoryEdit;
