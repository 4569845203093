import React, { useState } from "react";
import { Route, Routes, NavLink, useNavigate } from "react-router-dom";
import Inventory from "../pages/Inventory/Inventory";
import Settings from "../pages/Settings/settings";
import logo from "./zaiko.png";
import "./AuthenticatedApp.css";
import Footer from "./Footer/Footer";
import Feedback from "./Feedback/Feedback";
import InventoryDetail from "../pages/InventoryDetail/InventoryDetail";
import InventoryEdit from "../pages/InventoryEdit/InventoryEdit";
import Report from "../pages/Report/Report";

function AuthenticatedApp({ token, setToken }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  // const logActivity = async (type, details) => {
  //   try {
  //     await fetch(`${apiUrl}/log`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         type: type,
  //         details: details,
  //         timestamp: new Date().toISOString(),
  //       }),
  //     });
  //   } catch (error) {
  //     console.error("Error logging activity:", error);
  //   }
  // };

  const handleNavigation = (path) => {
    // logActivity("navigation", { path: path });
    navigate(path);
    setIsMobileMenuOpen(false); // Close mobile menu on navigation
  };

  return (
    <div className="app-container">
      <div className="main-content">
        <nav className="navbar">
          <div className="navbar-logo">
            <NavLink to="/home" onClick={() => handleNavigation("/home")}>
              <img src={logo} alt="Logo" />
            </NavLink>
          </div>
          <div className={`navbar-links ${isMobileMenuOpen ? "mobile" : ""}`}>
            <ul>
              <li>
                <NavLink
                  to="/home"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                  onClick={() => handleNavigation("/home")}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/inventory"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                  onClick={() => handleNavigation("/inventory")}
                >
                  Inventory
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                  onClick={() => handleNavigation("/settings")}
                >
                  Settings
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/add_feedback"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                  onClick={() => handleNavigation("/add_feedback")}
                >
                  Feedback
                </NavLink>
              </li>
            </ul>
          </div>
          <div
            className="hamburger"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </nav>
        <Routes>

          <Route path="/home" element={<Report token={token} />} />
          <Route
            path="/inventory"
            element={<Inventory token={token} setToken={setToken} />}
          />
          <Route
            path="/settings"
            element={<Settings token={token} setToken={setToken} />}
          />
          <Route
            path="/add_feedback"
            element={<Feedback token={token} setToken={setToken} />}
          />
          <Route
            path="/inventory_edit"
            element={<InventoryEdit token={token} setToken={setToken} />}
          />
          <Route
            path="/inventory_detail/:brand/:productName"
            element={<InventoryDetail token={token} setToken={setToken} />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default AuthenticatedApp;
