import React, { useState } from "react";
import "./Feedback.css";
import feedbackImage from "./feedback.png"; // Make sure to have an image file in the same directory

const Feedback = ({ token }) => {
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/feedback/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ feedback_text: feedback, rating: rating }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          `Failed to submit feedback: ${data.detail || "Unknown error"}`
        );
      }

      alert("Feedback submitted successfully!");
      setFeedback("");
      setRating(0);
    } catch (error) {
      alert(`Error submitting feedback: ${error.message}`);
    }
  };

  return (
    <div className="settings-container">
      <h1 className="settings-title">Feedback</h1>
      <img src={feedbackImage} alt="Feedback" className="feedback-image" />

      <p className="feedback-subtitle">
        Please rate your experience with Sustain-a-Plate below:
      </p>
      <div className="rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`star ${star <= rating ? "selected" : ""}`}
            onClick={() => setRating(star)}
          >
            ★
          </span>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="feedback-form">
        <label className="feedback-label">
          Additional feedback:
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="feedback-input"
            placeholder="My feedback"
          />
        </label>
        <div className="actions">
          <button type="submit" className="submit-button">
            Submit
          </button>
          <button
            type="button"
            className="cancel-button"
            onClick={() => {
              setFeedback("");
              setRating(0);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Feedback;
