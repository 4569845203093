import React, { useState, useEffect } from "react";
import Login from "./pages/Login/Login";
import AuthenticatedApp from "./components/AuthenticatedApp";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import QRCodeRedirectHandler from "./components/QRCodeRedirectHandler/QrCodeRedirectHandler";
import ViewInventory from "./components/ViewInventory/ViewInventory";
import EditInventory from "./components/EditInventory/EditInventory";
import LoadingPage from "./components/Loading/LoadingPage";
function App() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    return tokenString;
  };

  const [token, setToken] = useState(getToken());

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("token", token);
    } else {
      sessionStorage.removeItem("token");
    }
  }, [token]);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/loading" element={<LoadingPage />} />
          <Route path="/auth/reset-password" element={<PasswordReset />} />
          <Route
            path="/qrcode/:inventoryId"
            element={<QRCodeRedirectHandler token={token} />}
          />
          <Route
            path="/view-inventory/:inventoryId"
            element={<ViewInventory />}
          />
          {!token ? (
            <Route path="/*" element={<Login setToken={setToken} />} />
          ) : (
            <Route
              path="/*"
              element={<AuthenticatedApp token={token} setToken={setToken} />}
            />
          )}
          {token && (
            <Route
              path="/edit-inventory/:inventoryId"
              element={<EditInventory token={token} />}
            />
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
